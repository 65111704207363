import { addHours } from "date-fns";

export default function FormatDate({ signature }) {
  const generateDate = () => {
    const date = new Date(signature.startAtUTC).toLocaleDateString(undefined, {
      timeZone: signature.sessionId.timezone,
    });

    const startHour = new Date(signature.startAtUTC).toLocaleTimeString(
      navigator.language || "fr-FR",
      {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: signature.sessionId.timezone,
      }
    );

    const endHour = new Date(
      addHours(new Date(signature.startAtUTC), signature.duration / 60)
    ).toLocaleTimeString(navigator.language || "fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: signature.sessionId.timezone,
    });

    return `${date} de ${startHour} à ${endHour}`;
  };

  return <span style={{ flexShrink: 0 }}>{generateDate()}</span>;
}
