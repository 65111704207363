//useFetch.js
import { useState, useEffect } from "react";
import axios from "axios";
import { useLogout } from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";

function useFetch(url, config) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const { logout } = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    setData(null);
    setError(null);
    setStatusCode(null);

    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          cancelToken: source.token,
          ...config,
        });
        response.data && setData(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) logout();
        if (error?.response?.status === 403) navigate("/");
        // console.log(error.response.data);
        setError(error.response.data);
        // setError(
        //   error?.response?.data ||
        //     error?.response?.data?.message ||
        //     "Something went wrong hooks"
        // );
        setStatusCode(error?.response?.status);

        setIsLoading(false);
      }
    };
    fetchData();

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [url]);

  return { data, isLoading, error, statusCode };
}

export default useFetch;
