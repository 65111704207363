import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
// import { useWorkoutsContext } from "./useWorkoutsContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  //   const { dispatch: workoutsDispatch } = useWorkoutsContext();

  const logout = () => {
    // remove signer from storage
    localStorage.removeItem("signer");

    // dispatch logout action
    dispatch({ type: "LOGOUT" });
    // workoutsDispatch({ type: "SET_WORKOUT", payload: null });$

    navigate("/login");
  };

  return { logout };
};
