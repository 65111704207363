import { Link } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";

import { TextInput, Button, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import styled from "styled-components";
import AuthWrapper from "../../components/AuthWrapper";

const Login = () => {
  const { login, error } = useLogin();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email invalide"),
    },
  });

  const submit = async (values) => {
    await login(values.email, values.password);
  };

  return (
    <AuthWrapper>
      <LoginStyle>
        <form onSubmit={form.onSubmit((values) => submit(values))}>
          <h1>Identifiez-vous</h1>
          <TextInput
            // withAsterisk
            type="email"
            label="EMAIL"
            placeholder="nono@lereacteur.io"
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label="MOT DE PASSE"
            placeholder="Votre mot de passe"
            {...form.getInputProps("password")}
          />
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Button fullWidth type="submit">
            Se connecter
          </Button>
          <p className="link">
            Vous n'avez pas de compte ? <Link to="/signup">Créez-en un</Link> !
          </p>
          <p className="link">
            <Link to="/send-reset-email"> Mot de passe oublié ?</Link>
          </p>
        </form>
      </LoginStyle>
    </AuthWrapper>
  );
};

export default Login;

const LoginStyle = styled.div`
  /* input {
    font-size: 16px;
  } */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.75rem;

    h1 {
      font-weight: bold;
    }
    p {
      font-size: 0.9rem;
    }

    label {
      font-size: 0.75rem;
      color: #bbb;
    }

    .link {
      color: "#606060";
      text-align: center;
      a {
        text-decoration: underline;
      }
    }
  }

  /* @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .form-container {
      flex: 1;
      form {
        gap: 1rem;
        width: 100%;
        padding: 0 1rem;
      }
    }
  } */
`;
