import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter as Router } from "react-router-dom";
import { StyleSheetManager } from "styled-components";

import { MantineProvider } from "@mantine/core";

import Globals from "./assets/style/Globals";

// import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import { CustomFonts } from "./assets/style/CustomFonts";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <StyleSheetManager disableVendorPrefixes={true}>
    <MantineProvider
      theme={{
        fontFamily: "averta-regular",
        colors: {
          primaryPurple: [
            "#5C48D3",
            "#5C48D3",
            "#5C48D3",
            "#5C48D3",
            "#5C48D3",
            "#5C48D3",
            "#5C48D3",
          ],
        },
        /** Put your mantine theme override here */

        primaryColor: "primaryPurple",
      }}
    >
      <CustomFonts />
      <Globals />
      <AuthContextProvider>
        <Toaster
          toastOptions={{
            style: {
              fontFamily: "averta-regular",
              color: "black",
            },
          }}
        />
        <Router>
          <App />
        </Router>
      </AuthContextProvider>
    </MantineProvider>
  </StyleSheetManager>

  //</React.StrictMode>
);
