import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import logo from "../assets/img/logoSign.png";
import styled from "styled-components";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";

export default function Header() {
  const { logout } = useLogout();
  const { signer } = useAuthContext();

  return (
    <HeaderStyle className="main-container">
      <div className="header container">
        <Link to="/">
          <img src={logo} alt="logo le reacteur" />
        </Link>
        {signer && (
          <>
            <Button className="button-secondary absolute" onClick={logout}>
              Se déconnecter
            </Button>
          </>
        )}
      </div>
    </HeaderStyle>
  );
}

const HeaderStyle = styled.header`
  position: relative;
  background: var(--primary);

  position: fixed;
  width: 100%;
  /* padding-bottom: 4.6rem; */
  /* background: red; */
  z-index: 10;

  .header {
    background: var(--primary);
    height: 4.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
    }

    img {
      width: 10rem;
    }

    .absolute {
      position: absolute;
      right: 1rem;
    }
  }

  @media (max-width: 768px) {
    .header {
      justify-content: space-between;

      .absolute {
        position: initial;
        transform: none;
      }
    }
  }
`;
