import { useEffect, useRef, useState } from "react";

import { DateRangePicker } from "@mantine/dates";

import axios from "axios";
import { format } from "date-fns";
import styled from "styled-components";
import { useAuthContext } from "../../hooks/useAuthContext";
import useFetch from "../../hooks/useFetch";

export default function Admin_GeneratePdf() {
  const { signer } = useAuthContext();
  const linkRef = useRef(null);

  const { data: sessions, isLoading } = useFetch(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/sessions`,
    {
      headers: {
        Authorization: `Bearer ${signer.token}`,
      },
    }
  );

  const [sessionSelected, setSessionSelected] = useState(null);
  const [signerToSelect, setSignerToSelect] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [dates, setDates] = useState([null, null]);
  // const [good, setGood] = useState(false);
  const [isTeacherSignatureNeeded] = useState(false);
  // const [isPro, setIsPro] = useState(false);
  // const [extraTwoHourActivated, setExtraTwoHourActivated] = useState(false);
  const [teacherName, setTeacherName] = useState("Tom ROBERTO");
  const [isAnAlphatechCourse, setIsAnAlphatechCourse] = useState(false);

  const sendRequest = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/sessions/${sessionSelected._id}`,
      {
        headers: {
          Authorization: `Bearer ${signer.token}`,
        },
      }
    );

    setSessionSelected(response.data);
  };

  useEffect(() => {
    if (sessionSelected !== null) {
      sendRequest();
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (sessionSelected) {
      const { startAt, endAt } = sessionSelected;
      setDates([new Date(startAt), new Date(endAt)]);

      if (sessionSelected.isAnAlphatechCourse) {
        setIsAnAlphatechCourse(true);
      } else {
        setIsAnAlphatechCourse(false);
      }
      if (sessionSelected.teacher === "Tom") {
        setTeacherName("Tom ROBERTO");
      } else if (sessionSelected.teacher === "Lucas") {
        setTeacherName("Lucas ROBERTO");
      } else if (sessionSelected.teacher === "Alexis") {
        setTeacherName("Alexis MICHELOT");
        return;
      } else {
        setTeacherName("Tom ROBERTO");
      }
    }
  }, [sessionSelected]);

  return isLoading === true ? (
    <div>chargement</div>
  ) : (
    <AdminPdfStyle
      className="grid-container"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      }}
    >
      <div>
        {sessions.map((session, index) => {
          const sessionName = session.batch
            ? session.batch
            : session.description;
          return (
            <button
              key={index}
              style={{
                // color: session.isAnAlphatechCourse ? "red" : "black",

                opacity: !sessionSelected
                  ? 1
                  : sessionSelected?._id === session?._id
                  ? 1
                  : 0.3,
              }}
              onClick={() => {
                setSessionSelected(session);
                setRefresh(refresh + 1);
              }}
            >
              {sessionName} / {session.name}
              <span
                style={{
                  fontSize: "1.1rem",
                }}
              >
                {session.teacher ? session.teacher : "Nope"}
              </span>
            </button>
          );
        })}
      </div>
      <div>
        {sessionSelected === null ? (
          <div>Choisir une session</div>
        ) : (
          <div>
            <div>
              {sessionSelected.batch
                ? sessionSelected.batch
                : sessionSelected.description}
            </div>

            {sessionSelected &&
              sessionSelected.signers.map((signer, index) => {
                return (
                  <button
                    style={{
                      padding: ".5rem",
                      margin: ".25rem",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={() => {
                      setSignerToSelect(signer);
                    }}
                  >
                    {signer.firstName} {signer.lastName}
                  </button>
                );
              })}
          </div>
        )}
      </div>
      <div>
        <h2>Feuilles de présence de</h2>
        <h1>{signerToSelect && signerToSelect.firstName}</h1>
        {sessionSelected && (
          <DateRangePicker
            label="Chosir une range"
            placeholder="Pick dates range"
            value={dates}
            onChange={setDates}
            minDate={new Date(sessionSelected.startAt)}
            maxDate={new Date(sessionSelected.endAt)}
          />
        )}
        <br />
        {/* <label style={{ opacity: isTeacherSignatureNeeded ? "1" : 0.4 }}>
          <input
            type="checkbox"
            name="checkbox"
            value="value"
            onChange={() => setIsTeacherSignatureNeeded((prev) => !prev)}
          />
          Rajouter la signature du formateur pour l'Etat 🇫🇷
        </label> */}
        {/* <label style={{ opacity: isPro ? "1" : 0.4 }}>
          <input
            type="checkbox"
            name="checkbox"
            value={isPro}
            onChange={() => setIsPro((prev) => !prev)}
          />
          isPro
        </label>
        <br />
        <label style={{ opacity: extraTwoHourActivated ? "1" : 0.4 }}>
          <input
            type="checkbox"
            name="checkbox"
            value={extraTwoHourActivated}
            onChange={() => setExtraTwoHourActivated((prev) => !prev)}
          />
          Add Two hours to last sunday
        </label> */}
        <label style={{ opacity: isAnAlphatechCourse ? "1" : 0.4 }}>
          <input
            type="checkbox"
            name="checkbox"
            value={isAnAlphatechCourse}
            checked={isAnAlphatechCourse}
            onChange={() => setIsAnAlphatechCourse((prev) => !prev)}
          />
          isAnAlphatechCourse
        </label>
        <br />
        <br />
        {sessionSelected?.teacher === null && (
          <p style={{ color: "red" }}>
            Pas de formateur attitré ! Veuillez en sélectionner un !
          </p>
        )}
        <label style={{ opacity: isAnAlphatechCourse ? "1" : 0.4 }}>
          Nom du formateur :
        </label>
        <br />
        <select
          value={teacherName}
          onChange={(event) => setTeacherName(event.currentTarget.value)}
        >
          <option value="Tom ROBERTO">Tom ROBERTO</option>
          <option value="Lucas ROBERTO">Lucas ROBERTO</option>
          {/* <option value="Alexis MICHELOT">Alexis MICHELOT</option> */}
          <option value="Alexis MICHELOT">Alexis MICHELOT</option>
        </select>
        <br />
        <br />
        <button
          onClick={async () => {
            axios(
              `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures/generate-pdf`,
              {
                method: "POST",
                responseType: "blob", //Force to receive data in a Blob Format
                headers: {
                  Authorization: `Bearer ${signer.token}`,
                },
                data: {
                  signer: signerToSelect,
                  session: sessionSelected,
                  isTeacherSignatureNeeded: isTeacherSignatureNeeded,
                  // extraTwoHourActivated: extraTwoHourActivated,
                  // isPro: isPro,
                  isAnAlphatechCourse: isAnAlphatechCourse,
                  teacherName: teacherName,
                  targetDates: [
                    format(dates[0], "yyyy-MM-dd"),
                    //J'ajoute un jour au jour de fin pour qu'il englobe les signatures du dernier jour
                    format(dates[1], "yyyy-MM-dd"),
                  ],
                  // force: isForce,
                },
              }
            )
              .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                // window.open(fileURL);

                linkRef.current.href = fileURL;
                linkRef.current.download = `${signerToSelect.firstName}-${
                  signerToSelect.lastName
                }-${format(dates[0], "dd-MM-yyyy")} / ${format(
                  dates[1],
                  "dd-MM-yyyy"
                )}`;
                linkRef.current.click();
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Generate PDF
        </button>
        <a style={{ display: "none" }} ref={linkRef} href="https://example.com">
          Lien
        </a>

        <br></br>
        <br></br>
        <br></br>

        {signerToSelect && (
          <a
            rel="noreferrer"
            href={`/admin?id=${signerToSelect._id}`}
            target="_blank"
          >
            <button>
              Signatures de {signerToSelect.firstName} {signerToSelect.lastName}
            </button>
          </a>
        )}
      </div>
    </AdminPdfStyle>
  );
}

const AdminPdfStyle = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  padding-top: 4.6rem;
`;
