import { useAuthContext } from "../../hooks/useAuthContext";

import { useParams } from "react-router-dom";
import axios from "axios";

import { Button, Loader, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import styled from "styled-components";
import AuthWrapper from "../../components/AuthWrapper";
import toast from "react-hot-toast";
import { useState } from "react";

const Reset = () => {
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const { resetToken } = useParams();
  const { dispatch } = useAuthContext();

  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validate: {
      confirmPassword: (value, values) =>
        value !== values.password
          ? "Les mots de passe ne correspondent pas"
          : value.trim().length < 7
          ? "10 caractères minimum"
          : null,
    },
  });

  const submit = async (values) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signers/reset`,
        {
          password: values.password,
          resetToken: resetToken,
        }
      );

      if (!response.data) {
        setIsLoading(false);
        setError("Une erreur est survenue");
      }
      if (response.data) {
        // save the signer to local storage
        localStorage.setItem("signer", JSON.stringify(response.data));

        // update the auth context
        dispatch({ type: "LOGIN", payload: response.data });

        toast.success("Mot de passe mis à jour !");

        // update loading state
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <AuthWrapper>
      <ResetStyles>
        <form onSubmit={form.onSubmit((values) => submit(values))}>
          {isLoading ? (
            <Loader size="xl" />
          ) : (
            <>
              <h1>Reset de votre mot de passe </h1>
              <PasswordInput
                label=" NOUVEAU MOT DE PASSE"
                placeholder=""
                {...form.getInputProps("password")}
              />
              <PasswordInput
                label="CONFIRMEZ VOTRE NOUVEAU MOT DE PASSE"
                placeholder=""
                {...form.getInputProps("confirmPassword")}
              />

              {error && <p style={{ color: "red" }}>{error && error}</p>}

              <Button fullWidth type="submit">
                Modifier mon mot de passe
              </Button>
            </>
          )}
        </form>
      </ResetStyles>
    </AuthWrapper>
  );
};

export default Reset;

const ResetStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 25%;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.75rem;
    h1 {
      font-weight: bold;
    }
    p {
      font-size: 0.9rem;
    }

    label {
      font-size: 0.75rem;
      color: #bbb;
    }

    .link {
      text-align: center;

      color: "#606060";
      a {
        text-decoration: underline;
      }
    }
  }

  /* @media (max-width: 768px) {
    display: block;

    .left {
      justify-content: flex-start;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      justify-content: flex-start;
      padding: 0.75rem;
      padding: 0.75rem 0;
      .logo {
        width: 55%;
      }

      .responsive-logo {
        display: none;
      }
    }

    .form-container {
      min-height: calc(100vh - 12rem);
      form {
        gap: 1rem;
        width: 100%;
        padding: 0 1rem;
      }
    }
  } */
`;
