import Screenshot from "../components/Screenshot";

import styled from "styled-components";

import useFetch from "../hooks/useFetch";
import { useAuthContext } from "../hooks/useAuthContext";
import { Loader } from "@mantine/core";
import { useEffect } from "react";
import toast from "react-hot-toast";

export default function Sign({
  sessionId,
  signatureId,
  setSignatureId,
  remain,
  setLoadingSign,
  loadingSign,
  moreFetch,
}) {
  const { signer } = useAuthContext();

  const {
    data: signature,
    isLoading: isLoadingCheck,
    error,
    statusCode,
  } = useFetch(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures/check/${sessionId}/${signatureId}`,
    {
      headers: {
        Authorization: `Bearer ${signer.token}`,
      },
    }
  );

  useEffect(() => {
    (async () => {
      if (statusCode === 409) {
        if (error.allUnsignedSignaturesCount === 0) {
          setSignatureId(null);
        } else {
          setSignatureId(error.lastIdServer);
        }
        await moreFetch();
        toast.error(error.message);
      }
    })();
    // eslint-disable-next-line
  }, [signature, statusCode]);

  // // const isLoadingCheck = true;

  return (
    <SignStyle>
      {isLoadingCheck || loadingSign ? (
        <Loader size="xl" />
      ) : error ? (
        <h2>{error.message}</h2>
      ) : (
        <>
          <h3 className="title" style={{ textAlign: "center" }}>
            <span>{signature.signerId.firstName}</span>, merci de signer dans le
            cadre. Pas de <span>trait</span>, de <span>croix</span>, de
            <span> d'initiales</span>, ni de <span>nom</span> ou{" "}
            <span>prénom</span> merci !
          </h3>

          <Screenshot
            signatureId={signatureId}
            sessionId={sessionId}
            setSignatureId={setSignatureId}
            setLoadingSign={setLoadingSign}
            moreFetch={moreFetch}
          />
        </>
      )}
    </SignStyle>
  );
}

const SignStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  height: 100%;

  .title {
    span {
      color: var(--primary);
    }
  }

  /* @media (max-width: 768px) {
    justify-content: space-around !important;
    justify-content: center;
    gap: 1rem;
  } */
`;
