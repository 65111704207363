import { Link } from "react-router-dom";
import { Loader } from "@mantine/core";
import styled from "styled-components";
import { useAuthContext } from "../../hooks/useAuthContext";
import useFetch from "../../hooks/useFetch";

export default function Home() {
  const { signer } = useAuthContext();

  const { data: user, isLoading } = useFetch(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signers`,
    {
      headers: {
        Authorization: `Bearer ${signer.token}`,
      },
    }
  );

  //Reset url on Home Page
  window.history.pushState({}, null, "/");

  return (
    <HomeStyle className="main-container">
      <div className="home container content">
        {isLoading ? (
          <Loader size="xl" />
        ) : (
          <>
            <h1 className="title">Hello {user.firstName} 👋</h1>

            {user.registeredSessions.length === 0 ? (
              <p>Tu n'as accès à aucune signature ! 🙁</p>
            ) : (
              <>
                <p className="subtitle">Voici les sessions à émarger :</p>

                <div className="item-container">
                  {user.registeredSessions.map((session) => {
                    return (
                      <Link
                        style={{
                          pointerEvents:
                            session.allUnsignedSignatures === 0
                              ? "none"
                              : "auto",
                          filter:
                            session.allUnsignedSignatures === 0 &&
                            "grayscale(1)",
                        }}
                        key={session._id}
                        to={`/calendar/${session._id}`}
                      >
                        <p className="item">{session.name}</p>
                        {/* <p className="item">{session.batch}</p> */}
                      </Link>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </HomeStyle>
  );
}

const HomeStyle = styled.div`
  padding-top: 4.6rem;
  background: var(--darken-white);
  min-height: 100vh;
  display: flex;

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin: 0.25rem 0;
  }
  .subtitle {
    margin: 1rem 0 2rem;
  }
  .item {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 4px;
    text-align: center;
    background: var(--white);
    color: var(--primary);
    font-size: 0.875rem;
    font-weight: bold;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: border ease 0.2s;

    &:hover {
      border: 1px solid var(--primary);
      transition: border ease 0.2s;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(var(--vh, 1vh) * 100);
    display: block;
    justify-content: flex-start;

    .home {
      margin-top: 1rem;
      .title {
        margin: 0.75rem 0;
      }
      .subtitle {
        margin: 0 0 1.25rem;
      }
    }
  }
`;
