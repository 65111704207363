import styled from "styled-components";
import { Center } from "@mantine/core";
import { Link } from "react-router-dom";
import logo from "../assets/img/logoSign.png";

export default function AuthWrapper({ children }) {
  return (
    <AuthWrapperStyle>
      <Center className="left">
        <Link className="logo-container" to="/">
          <img className="logo" src={logo} alt="logo le reacteur" />
        </Link>
      </Center>
      {children}
    </AuthWrapperStyle>
  );
}

const AuthWrapperStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  min-height: 100vh;

  img {
    width: 20rem;
  }

  .left {
    background: var(--primary);
    height: 100%;
    padding: 0 1rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .left {
      justify-content: flex-start;

      .logo-container {
        display: flex;
        justify-content: center;
        justify-content: flex-start;
        padding: 0.75rem 0;
        .logo {
          width: 55%;
        }

        .responsive-logo {
          display: none;
        }
      }
    }
  }
`;
