import styled from "styled-components";

const Lab = () => {
  return (
    <LabStyle>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur
        possimus asperiores sit fugit perspiciatis rerum sunt nostrum, quidem
        quia officia facere, dolorum fuga beatae! Consequatur dolore deserunt
        quis rem in.
      </p>
    </LabStyle>
  );
};

export default Lab;

const LabStyle = styled.div`
  background: red;
  padding-top: 4.6rem;

  min-height: calc(var(--vh, 1vh) * 100);
  /* min-height: 100vh; */
`;
