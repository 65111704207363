import { Global } from "@mantine/core";
// import bold from "../fonts/AvertaStdPE-Bold.woff2";
import regular from "../fonts/AvertaStdPE-Regular.woff2";

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "averta-regular",
            src: `url('${regular}') format("woff2")`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        // {
        //   "@font-face": {
        //     fontFamily: "Greycliff CF",
        //     src: `url('${heavy}') format("woff2")`,
        //     fontWeight: 900,
        //     fontStyle: "normal",
        //   },
        // },
      ]}
    />
  );
}
