// import { Link } from "react-router-dom";
// import { usePost } from "../../hooks/usePost";

import axios from "axios";

import { TextInput, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import styled from "styled-components";
import AuthWrapper from "../../components/AuthWrapper";
import toast from "react-hot-toast";
import { useState } from "react";

const SendResetEmail = () => {
  const [isDisable, setIsDisable] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email invalide"),
    },
  });

  const submit = async (values) => {
    try {
      setIsDisable(true);

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signers/send-reset-email`,
        {
          email: values.email,
        }
      );

      toast.success(res.data);
      setIsDisable(true);
    } catch (error) {
      setIsDisable(false);

      toast.error("Une erreur est survenue");
    }
  };

  return (
    <AuthWrapper>
      <LoginStyle>
        <form onSubmit={form.onSubmit((values) => submit(values))}>
          <h1>
            Modifiez votre <br /> mot de passe
          </h1>
          <p>
            Veuillez saisir l'adresse avec laquelle vous avez créé votre compte.
          </p>
          <TextInput
            // withAsterisk
            type="email"
            label="EMAIL"
            placeholder="nono@lereacteur.io"
            {...form.getInputProps("email")}
          />

          {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
          <Button disabled={isDisable} fullWidth type="submit">
            {isDisable ? "Email envoyé !" : "Modifier mon mot de passe"}
          </Button>
        </form>
      </LoginStyle>
    </AuthWrapper>
  );
};

export default SendResetEmail;

const LoginStyle = styled.div`
  /* input {
    font-size: 16px;
  } */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: red; */
  max-width: 25%;
  margin: 0 auto;
  /* text-align: center; */
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.75rem;

    h1 {
      font-weight: bold;
    }
    p {
      font-size: 0.9rem;
    }

    label {
      font-size: 0.75rem;
      color: #bbb;
    }

    .link {
      color: "#606060";
      a {
        text-decoration: underline;
      }
    }
  }
`;
