import { useRef } from "react";
import { useScreenshot } from "use-react-screenshot";

import { useAuthContext } from "../hooks/useAuthContext";
import styled from "styled-components";

import { Button } from "@mantine/core";

import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import toast from "react-hot-toast";
import { useHotkeys } from "@mantine/hooks";

const Screenshot = ({
  signatureId,
  sessionId,
  setSignatureId,
  setLoadingSign,
  moreFetch,
}) => {
  const canvasRef = useRef(null);
  const screenshotRef = useRef(null);
  const takeScreenShot = useScreenshot()[1];
  const { signer } = useAuthContext();
  const clearCanvas = () => canvasRef.current.clear();
  useHotkeys([["enter", () => getImage()]]);

  const getImage = async () => {
    try {
      if (canvasRef.current.isEmpty() === true) {
        toast.error("Merci de signer correctement !");
      } else {
        setLoadingSign(true);

        const signatureScreenshot = await takeScreenShot(screenshotRef.current);
        const res = await fetch(signatureScreenshot);
        const blob = await res.blob();
        const formData = new FormData();
        formData.append("photo", new File([blob], "filename.jpeg"));
        formData.append("signatureId", signatureId);
        formData.append("sessionId", sessionId);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures/sign`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${signer.token}`,
            },
          }
        );
        if (data) {
          if (data.signatures.allUnsignedSignaturesCount === 0) {
            setSignatureId(null);
          } else {
            setSignatureId(data.signatures.lastIdServer);
          }
          await moreFetch();
          // setLoadingSign(false);
        }
      }
    } catch (error) {
      // console.log(error?.response?.data);
      if (error?.response.status === 409) {
        setSignatureId(error?.response?.data.lastIdServer);
        toast.error(error?.response?.data.message);
        await moreFetch();
        setLoadingSign(false);
      }
    }
  };

  return (
    <StyleSign>
      <div className="screenshot-container">
        <div ref={screenshotRef} style={{}}>
          <SignatureCanvas
            ref={canvasRef}
            canvasProps={{
              className: "sig-canvas",
            }}
          />
        </div>
      </div>

      <div className="double-buttons">
        <Button variant="outline" size="md" onClick={clearCanvas}>
          Effacer
        </Button>
        <Button size="md" onClick={getImage}>
          Valider
        </Button>
      </div>
    </StyleSign>
  );
};

export default Screenshot;

const StyleSign = styled.div`
  .screenshot-container {
    border: 1px solid var(--primary);
    display: flex;
    .sig-canvas {
      width: 100%;
      height: 200px;
    }
  }
  .double-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin: 1rem 0 0;
  }
`;

// xs
// sm
// md
// lg
// xl
