import { useParams } from "react-router-dom";

import { isSameDay } from "date-fns";

import styled from "styled-components";

import { useAuthContext } from "../../hooks/useAuthContext";

import { Loader } from "@mantine/core";
import useFetchHomeMade from "../../hooks/useFetchHomeMade";

import axios from "axios";

//components
import CalendarSlot from "../../components/CalendarSlot";
import Sign from "../../components/Sign";
import { useState, useEffect } from "react";

export default function Session() {
  const { signer } = useAuthContext();
  const { sessionId } = useParams();
  const [loadingSign, setLoadingSign] = useState(false);

  const {
    data,
    setData,
    isLoading,
    signatureId,
    setSignatureId,
    dateOfTheDay,
    setDateOfTheDay,
  } = useFetchHomeMade(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures/session/${sessionId}`,
    {
      headers: {
        Authorization: `Bearer ${signer.token}`,
      },
    }
  );

  // const isLoading = true;

  const moreFetch = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures/session/${sessionId}`,
      {
        headers: {
          Authorization: `Bearer ${signer.token}`,
        },
      }
    );
    setData(response.data);
    setDateOfTheDay(new Date(response.data.signatures.dateOfTheDayServer));
    setSignatureId(response.data.signatures.signatureIdServer);
  };

  let remain;

  if (data) {
    remain = data.signatures.allUnsignedSignaturesCount;
  }

  useEffect(() => {
    if (data !== null && data.signatures.allUnsignedSignaturesCount > 0) {
      const allUnsignedSignatures = data.signatures.allUnsignedSignatures;
      const result = allUnsignedSignatures.filter((signature) =>
        isSameDay(dateOfTheDay, Date.parse(signature.startAtUTC))
      );

      setSignatureId(result[0]._id);
    }
    // eslint-disable-next-line
  }, [dateOfTheDay]);

  useEffect(() => {
    if (loadingSign === true) {
      setLoadingSign(false);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <MySignaturesStyle className="main-container">
      <div className="container">
        {!isLoading &&
        data.signatures.allUnsignedSignaturesCount ===
          0 ? null : !signatureId || isLoading ? (
          <div className="main-title-container">
            <p>
              <Loader />
            </p>
          </div>
        ) : (
          <div className="main-title-container">
            <div className="main-title">
              <h1 className="title">
                {data.session.name && data.session.name}
              </h1>
              <p className="text">
                Il te reste&nbsp;
                <span className="remain">{remain} </span>
                {remain > 1 ? "créneaux" : "créneau"}
                &nbsp;à émarger !
              </p>
            </div>
          </div>
        )}

        <div className="session-container">
          {!isLoading && data.signatures.allUnsignedSignaturesCount === 0 ? (
            <div className="all-good">
              <h3>{data.signatures.message}</h3>
            </div>
          ) : !signatureId || isLoading ? (
            <Loader />
          ) : (
            <>
              <CalendarSlot
                isLoading={isLoading}
                dateOfTheDay={dateOfTheDay}
                setDateOfTheDay={setDateOfTheDay}
                setSignatureId={setSignatureId}
                signatureId={signatureId}
                data={data}
                loadingSign={loadingSign}
              />

              <Sign
                remain={remain}
                sessionId={sessionId}
                signatureId={signatureId}
                setSignatureId={setSignatureId}
                data={data}
                dateOfTheDay={dateOfTheDay}
                setLoadingSign={setLoadingSign}
                loadingSign={loadingSign}
                moreFetch={moreFetch}
              />
            </>
          )}
        </div>
      </div>
    </MySignaturesStyle>
  );
}

const MySignaturesStyle = styled.div`
  background: var(--darken-white);

  padding-top: 4.6rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .main-title-container {
    height: 98.67px;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-title {
      text-align: center;
      margin-bottom: 0.75rem;
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 1.3rem;
        span {
          font-size: 2rem;
          color: var(--primary);
          font-weight: bold;
        }
      }
    }
  }

  .session-container {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 4px;
    background: var(--white);
    width: 788px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;

    .session {
      span {
        font-size: 1.75rem;
      }
    }
  }

  .remain {
    transform: scale(0.8);
    font-size: 3rem !important;
    animation: animateHeart 1.2s;
    animation-iteration-count: 2;
  }

  @keyframes animateHeart {
    0% {
      transform: scale(0.8);
    }
    5% {
      transform: scale(0.9);
    }
    10% {
      transform: scale(0.8);
    }
    15% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(0.8);
    }
  }

  /* @media (max-width: 768px) {
    .session {
      display: block;
      margin-top: 0.75rem;

      .text {
        margin-bottom: 0;
      }
    }
  } */
`;
