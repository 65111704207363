import styled from "styled-components";
import { Button } from "@mantine/core";
import toast from "react-hot-toast";

export default function NotOnMobile() {
  window.history.pushState({}, null, "/");
  return (
    <NotOnMobileStyle>
      <h2>Merci d'ouvrir ce lien depuis un ordinateur ! 💻</h2>
      <Button
        onClick={() => {
          toast("Lien copié !", {
            icon: "👌",
          });
          navigator.clipboard.writeText(window.location.href);
        }}
      >
        Copier le lien dans le presse papier
      </Button>
    </NotOnMobileStyle>
  );
}

const NotOnMobileStyle = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--darken-white);
  padding: 0 2rem;
`;
