import { createGlobalStyle } from "styled-components";

import avertaRegularWoff from "../fonts/AvertaStdPE-Regular.woff";
import avertaRegularWoff2 from "../fonts/AvertaStdPE-Regular.woff2";

import avertaBoldWoff from "../fonts/AvertaStdPE-Bold.woff";
import avertaBoldWoff2 from "../fonts/AvertaStdPE-Bold.woff2";

export default createGlobalStyle`
@font-face {
    font-family: 'averta-regular';
    src: local('averta-regular'), local('averta-regular'),
    url(${avertaRegularWoff2}) format('woff2'),
    url(${avertaRegularWoff}) format('woff');
}
    @font-face {
    font-family: 'averta-extra-bold';
    src: local('averta-extra-bold'), local('averta-extra-bold'),
    url(${avertaBoldWoff2}) format('woff2'),
    url(${avertaBoldWoff}) format('woff');
}


 :root {
    --primary: #5C47D3;
    /* --primary: #5C48D3; */
    --white: #ffffff;
    --darken-white: #F3F7FD;
  }


*{
  font-family: "averta-regular", sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.main-container {
  /* padding-top: 4.6rem; */

  padding-left: 0.75rem;
  padding-right: 0.75rem;

     @media (max-width: 768px) {
padding-left: 0 !important;
  padding-right: 0 !important;
  }
}
.container {
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 1rem;
       @media (max-width: 768px) {
  max-width: 100% !important;
  }
}

.content {
  /* min-height: calc(100vh - 4.6rem); */
  /* min-height: 100vh; */
  /* margin-top: 4.6rem; */
}

.button-primary {
  background: var(--primary);
  &:hover {
    background:  var(--primary);
    opacity: .8;
}
}

.button-secondary {
  background: white;
  border: 1px solid white;
  border:  1px solid  var(--primary);
  color:  var(--primary);
    &:hover {
    background:white;
    opacity: .8;
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin: 1rem 0;
}





.header {
  font-size: 2rem;
} 
`;
