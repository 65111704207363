import "./assets/style/globals.css";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import useVH from "react-viewport-height";
import { isMobile } from "react-device-detect";

//pages
import Home from "./pages/Signer/Home";
import Session from "./pages/Signer/Session";
import Admin from "./pages/Admin/Admin";
import GeneratePdf from "./pages/Admin/Admin_GeneratePdf";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import SendResetEmail from "./pages/Auth/SendResetEmail";
import NotOnMobile from "./pages/NotOnMobile";
import Lab from "./pages/Lab";

//components
import Header from "./components/Header";
import Reset from "./pages/Auth/Reset";

export default function App() {
  const { signer, isLoggedInLoading } = useAuthContext();
  const { pathname } = useLocation();
  const onlyAuth =
    pathname === "/login" ||
    pathname === "/signup" ||
    pathname === "/send-reset-email" ||
    //TO FIX
    pathname.split("/")[1] === "reset";
  useVH();

  if (isLoggedInLoading) return <div>Loading...</div>;

  if (isMobile) return <NotOnMobile />;
  return (
    <div className="App">
      {!onlyAuth && <Header />}

      <Routes>
        <Route path="/lab" element={<Lab />} />
        <Route
          path="/"
          element={signer ? <Home /> : <Navigate to="/login" />}
        />

        <Route
          path="/calendar/:sessionId"
          element={signer ? <Session /> : <Navigate to="/login" />}
        />

        <Route
          path="/admin"
          element={signer ? <Admin /> : <Navigate to="/login" />}
        />
        <Route
          path="/admin-generate-pdf"
          element={signer ? <GeneratePdf /> : <Navigate to="/login" />}
        />

        <Route
          path="/login"
          element={!signer ? <Login /> : <Navigate to="/" />}
        />
        <Route
          path="/signup"
          element={!signer ? <Signup /> : <Navigate to="/" />}
        />
        <Route
          path="/send-reset-email"
          element={!signer ? <SendResetEmail /> : <Navigate to="/" />}
        />
        <Route
          path="/reset/:resetToken"
          element={!signer ? <Reset /> : <Navigate to="/" />}
        />
        <Route
          path="*"
          element={signer ? <Home /> : <Navigate to="/login" />}
        />
      </Routes>
    </div>
  );
}
