import { Indicator } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import { isSameDay, isAfter } from "date-fns";

import "dayjs/locale/fr";

import styled from "styled-components";
import FormatDate from "./FormatDate";
import { Button } from "@mantine/core";

export default function CalendarSlot({
  data,
  signatureId,
  setSignatureId,
  dateOfTheDay,
  setDateOfTheDay,
  loadingSign,
}) {
  let counter = 0;

  return (
    <CalendarSlotStyle loadingSign={loadingSign}>
      <div className="left-calendar hidden-mobile">
        <Calendar
          allowLevelChange={false}
          locale="fr"
          value={dateOfTheDay}
          minDate={new Date(data.session.startAt)}
          maxDate={new Date(data.session.endAt)}
          onChange={(date) => {
            setDateOfTheDay(date);
          }}
          hideOutsideDates={false}
          initialMonth={
            isAfter(new Date(), new Date(data.session.endAt))
              ? new Date(data.session.startAt)
              : new Date(
                  data.signatures.allUnsignedSignatures[
                    data.signatures.allUnsignedSignatures.length - 1
                  ].startAtUTC
                )
          }
          excludeDate={(date) => {
            return !data.signatures.allUnsignedSignatures.some((element) => {
              // console.log(new Date(element.startAtUTC));
              return isSameDay(new Date(element.startAtUTC), date);
            });
          }}
          renderDay={(date) => {
            const isThereASignatureForThisDay =
              data.signatures.allUnsignedSignatures.filter((signature) =>
                isSameDay(date, Date.parse(signature.startAtUTC))
              );
            const isThereASignatureForThisDayAndIsThisToday =
              isThereASignatureForThisDay.filter(
                (signature) => signature.isToday === true
              );
            return (
              <Indicator
                size={8}
                color={
                  isThereASignatureForThisDayAndIsThisToday.length > 0
                    ? "green"
                    : isThereASignatureForThisDay.length > 0
                    ? "#fa5252"
                    : "transparent"
                }
                offset={8}
              >
                <div>{date.getDate()}</div>
              </Indicator>
            );
          }}
        />
      </div>
      <div className="left-list">
        <div className="calendar-list">
          {data.signatures.allUnsignedSignatures.map((signature) => {
            const filterDateSelected = isSameDay(
              dateOfTheDay || new Date(data.signatures.dateOfTheDayServer),
              Date.parse(signature.startAtUTC)
            );

            if (filterDateSelected) {
              counter++;
            }

            return (
              filterDateSelected && (
                <Button
                  key={signature._id}
                  onClick={() => {
                    setSignatureId(signature._id);
                  }}
                  fullWidth
                  variant={signatureId === signature._id ? "" : "outline"}
                  size="md"
                >
                  <FormatDate signature={signature} />
                </Button>
              )
            );
          })}
          {counter === 1 && (
            <div
              style={{
                background: "transparent",
                opacity: 0,
                cursor: "default",
              }}
            >
              <Button disabled fullWidth variant="outline" size="md">
                ---------------------------
              </Button>
            </div>
          )}
        </div>
      </div>
    </CalendarSlotStyle>
  );
}

const CalendarSlotStyle = styled.div`
  pointer-events: ${({ loadingSign }) => (loadingSign ? "none" : "auto")};

  //TO FIX !!!!!!!!!
  button[data-outside] {
    color: inherit;
  }

  //TO FIX !!!!!!!!!
  button[data-selected] div div {
    color: var(--white);
  }

  //TO FIX !!!!!!!!!
  button[data-weekend] {
    color: initial;
  }

  .left-list {
    .calendar-list {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  /* @media (max-width: 768px) {
    padding: 0;
    background: var(--darken-white);
    width: 100%;

    .calendar-list {
      height: initial !important;

      .item {
        transform: none !important;
        margin-top: 0.75rem !important;
        padding: 0.75rem 0.5rem;
      }
    }
    .hidden-mobile {
      display: none !important;
    }
  } */
`;
