//useFetch.js
import { useState, useEffect } from "react";
import axios from "axios";
import { useLogout } from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";

function useFetch(url, config, watch) {
  const [data, setData] = useState(null);
  const [signatureId, setSignatureId] = useState(null);
  const [dateOfTheDay, setDateOfTheDay] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const { logout } = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const source = axios.CancelToken.source();
    const fetchData = async () => {
      // console.log("🇫🇷🇫🇷🇫🇷🇫🇷🇫🇷FETCH🇫🇷🇫🇷🇫🇷🇫🇷 🇫🇷🇫🇷");
      try {
        const response = await axios.get(url, {
          cancelToken: source.token,
          ...config,
        });
        response.data && setData(response.data);
        setDateOfTheDay(new Date(response.data.signatures.dateOfTheDayServer));
        setSignatureId(response.data.signatures.signatureIdServer);
        setIsLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) logout();
        if (error?.response?.status === 403) navigate("/");

        setError(
          error?.response?.data?.error ||
            error?.response?.data?.message ||
            "Something went wrong"
        );
        setIsLoading(false);
        setStatusCode(error?.response?.status);
      }
    };
    fetchData();

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [url, watch]);

  return {
    data,
    setData,
    isLoading,
    error,
    statusCode,
    signatureId,
    setSignatureId,
    dateOfTheDay,
    setDateOfTheDay,
  };
}

export default useFetch;
