import { useSignup } from "../../hooks/useSignup";

import { Link } from "react-router-dom";

import { TextInput, Button, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import styled from "styled-components";
import AuthWrapper from "../../components/AuthWrapper";

const Signup = () => {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      confirmPassword: (value, values) =>
        value !== values.password
          ? "Les mots de passe ne correspondent pas"
          : value.trim().length < 7
          ? "10 caractères minimum"
          : null,
    },
  });

  const { signup, error } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const submit = async (values) => {
    await signup(values.email, values.password);
  };

  return (
    <AuthWrapper>
      <SignUpStyle onSubmit={handleSubmit}>
        <form onSubmit={form.onSubmit((values) => submit(values))}>
          <h1>Créez votre compte</h1>
          {/* <p>Créez votre compte pour continuer sur Apollo Sign</p> */}
          <TextInput
            // withAsterisk
            type="email"
            label="EMAIL"
            placeholder="nono@lereacteur.io"
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label="MOT DE PASSE"
            placeholder=""
            {...form.getInputProps("password")}
          />
          <PasswordInput
            label="CONFIRMEZ VOTRE MOT DE PASSE"
            placeholder=""
            {...form.getInputProps("confirmPassword")}
          />

          {error && <p style={{ color: "red" }}>{error && error}</p>}
          <Button fullWidth type="submit">
            Créer un compte
          </Button>
          <p className="link">
            <Link to="/login">Se connecter à un compte existant</Link>
          </p>
        </form>
      </SignUpStyle>
    </AuthWrapper>
  );
};

export default Signup;

const SignUpStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.75rem;
    h1 {
      font-weight: bold;
    }
    p {
      font-size: 0.9rem;
    }

    label {
      font-size: 0.75rem;
      color: #bbb;
    }

    .link {
      text-align: center;

      color: "#606060";
      a {
        text-decoration: underline;
      }
    }
  }

  /* @media (max-width: 768px) {
    display: block;

    .left {
      justify-content: flex-start;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      justify-content: flex-start;
      padding: 0.75rem;
      padding: 0.75rem 0;
      .logo {
        width: 55%;
      }

      .responsive-logo {
        display: none;
      }
    }

    .form-container {
      min-height: calc(100vh - 12rem);
      form {
        gap: 1rem;
        width: 100%;
        padding: 0 1rem;
      }
    }
  } */
`;
