import { createContext, useReducer, useEffect, useState } from "react";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { signer: action.payload };
    case "LOGOUT":
      return {
        signer: null,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    signer: null,
  });
  const [isLoggedInLoading, setIsLoggedInLoading] = useState(true);

  useEffect(() => {
    const signer = JSON.parse(localStorage.getItem("signer"));

    if (signer) {
      dispatch({ type: "LOGIN", payload: signer });
      setIsLoggedInLoading(false);
    } else {
      setIsLoggedInLoading(false);
    }
  }, []);

  // console.log("AuthContext state ", state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch, isLoggedInLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
